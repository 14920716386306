import React from "react";
import { PhoneScreenDataType } from "@components/phone-screen";
import mobileLoans from "@images/phone-screens/mobile-loans.png";
import mobileMoney from "@images/phone-screens/mobile-money-sent.png";

export const PHONE_SCREENS: Record<string, PhoneScreenDataType> = {
  "Set it and forget it!": {
    gray: "forget it",
    description:
      "Credit building program is designed to be very simple. With online sign up and automatic payments, you don't even need to leave the comfort of your own home. We manage everything for you!",
    img: mobileLoans,
  },
  "Boost your credit score": {
    gray: "credit score",
    description: (
      <>
        All payments automatically report to the credit bureaus and positively
        impact your credit score with every payment made. Just make sure that
        you have enough funds in your account.
        <br />
        <br />
        You have 12 months access to your Credit Score monitoring and financial
        tips at any time.
      </>
    ),
    img: mobileMoney,
  },
  "Build savings": {
    gray: "savings",
    description:
      "Your credit building program is designed to help save money and build credit at the same time. Your money is save and secure throughout the all period (12 month). At the end of the program, you not only have a brand new credit score, but a nice chunk of cash, too.",
    img: mobileLoans,
  },
  "Better credit = lower interest": {
    gray: "lower interest",
    description:
      "As your credit score improves, you'll gain access to better interest rates and different credit products, such as a mortgage, car loans, which is better for you. Over time, this can save you money.",
    img: mobileMoney,
  },
};
