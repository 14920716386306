import * as React from "react";

import { KeyValueItem, KeyValues, Layout, LayoutDescription, LayoutHeader, LayoutImage, LayoutTitle } from "@brainfinance/icash-component-library";
import { generateTitle } from "@helpers/generateTitle";
import { LayoutProps } from "@types";
import { KEYVALUESITEMS } from "./key-values-data";
import defaultImg from "@images/default.png";

export const KeyValuesSection = (props:LayoutProps) => (
  <Layout>
    <LayoutHeader>
      <LayoutTitle className="max-w-[645px]">
        {generateTitle({
          title: props.title,
          gray: props.gray,
        })}
      </LayoutTitle>
      <LayoutDescription className="max-w-[645px]">{props.description}</LayoutDescription>
      <LayoutImage src={props.src} placement="md-right" />
    </LayoutHeader>
    <KeyValues btnText="Start building my credit">
      {KEYVALUESITEMS.map((item, ndx) => (
        <KeyValueItem key={ndx} {...item} />
      ))}
    </KeyValues>
  </Layout>
);

KeyValuesSection.defaultProps = {
  title: "More details about the credit builder",
  gray: "credit builder",
  description:
    "Nullam sit amet eros ac nunc volutpat aliquet id quis tellus. Duis eget laoreet justo. Sed ac sem justo. Donec cursus rhoncus risus ac hendrerit. Nullam scelerisque hendrerit justo.",
  src: defaultImg
}