import React from "react";
import logoCdic from "@images/logo-cdic.png";

export const QUESTIONSPERSONAL = [
  {
    children: "Do you report both credit bureaus Equifax and TransUnion?",
    answer: "Yes"
  },
  {
    children: "Can I payoff or close my account early?",
    answer: "Yes"
  },
  {
    children: "Can I monitor my credit score?",
    answer: "Yes"
  },
  {
    children: "Is this a traditional personal loan?",
    answer: "Yes"
  },
  {
    children: "Can I spend the loan amount today?",
    answer: "Yes"
  }
];

export const QUESTIONSCREDITBUILDER = [
  {
    children: "Do you report both credit bureaus Equifax and TransUnion?",
    answer: "Yes"
  },
  {
    children: (
      <>
        Is it CDIC insured?
        <img
          src={logoCdic}
          className="inline-block ml-[22px]"
          style={{ width: "56px", height: "24px" }}
          alt="Deposit protection des impots"
        />
      </>
    ),
    answer: "Yes"
  },
  {
    children: "Can I payoff or close my account early?",
    answer: "Yes"
  },
  {
    children: "Can I monitor my credit score?",
    answer: "Yes"
  },
  {
    children: "Is this a traditional personal loan?",
    answer: "No"
  },
  {
    children: "Can I spend the loan amount today?",
    answer: "No"
  },
  {
    children: "Is there a hard pull on my credit?",
    answer: "No"
  }
];