import * as React from "react";
import { Box } from "@components/box";
import { Button } from "@brainfinance/icash-component-library";
import { Paragraph, GrayPhrase } from "@components/typography";

import { PhoneScreenTextBoxProps } from "../types";

import { generateTitle } from "../../../helpers";

const generateThresholds = () => {
  let thresholds: number[] = [];
  for (let i = 0; i <= 100; i++) {
    thresholds.push(i / 100);
  }
  return thresholds;
};

export const PhoneScreenTextBox: React.FC<PhoneScreenTextBoxProps> = (
  props
) => {
  const boxRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        props.onVisibilityChange(entry.intersectionRatio);
      },
      { threshold: generateThresholds() }
    );

    if (boxRef.current) observer.observe(boxRef.current);

    return () => {
      if (boxRef.current) observer.unobserve(boxRef.current);
    };
  }, []);

  return (
    <Box
      ref={boxRef}
      key={props.title}
      className="pt-13 last:pt-[15rem] lg:pt-10 lg:last:pt-12 sm:pt-2 h-[100vh] sm:h-auto sm:mb-[-3rem]"
    >
      {generateTitle({
        title: props.title,
        gray: props.gray,
      })}
      <Paragraph className="mt-3 md:mt-2 mb-3 md:mb-1.75">
        <GrayPhrase>{props.description}</GrayPhrase>
      </Paragraph>
      <Button appearance="primary" onClick={() => {}}>
        Start building my credit
      </Button>
    </Box>
  );
};
