import React from "react";
import { QuestionsTable, QuestionsTableContainer } from "@brainfinance/icash-component-library";
import { generateTitle } from "@helpers/generateTitle";
import { QuestionsTableSectionProps } from "./questions-table";

export const QuestionsTableSection = (props:React.PropsWithChildren<QuestionsTableSectionProps>) => (
  <QuestionsTable>
    {generateTitle({
      title: props.title,
      gray: props.gray,
    })}
    <QuestionsTableContainer className="mt-[2.5rem]">
      {props.children}
    </QuestionsTableContainer>
  </QuestionsTable>
);

QuestionsTableSection.defaultProps = {
  title: "Most common questions",
  gray: "questions"
}
