import React from "react";

export const KEYVALUESITEMS = [
  {
    label: "Loan size",
    children: (
      <>
        <span className="text-[15px] font-400 text-interface-200 sm:text-[13px]">$</span> 1,000
      </>
    )
  },
  {
    label: "Terms",
    children: (
      <>
        12 <span className="text-[15px] font-400 text-interface-200 sm:text-[13px]">months</span>
      </>
    )
  },
  {
    label: "Interest rate",
    children: (
      <>
        15.99 <span className="text-[15px] font-400 text-interface-200 sm:text-[13px]">% per annum</span>
      </>
    )
  }
];