import * as React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import { PageLayout, SEO } from "@components/page-layout";
import { GrayPhrase } from "@components/typography";
import { StepsSection } from "@containers/steps";
import { KeyValuesSection } from "@containers/key-values";
import { CreditGradeSection } from "@containers/credit-grade";
import { StatisticsList } from "@containers/statistics";
import { Testimonials } from "@containers/testimonials";
import { CustomerService } from "@containers/customer-service";
import { PhoneScreens } from "@containers/phone-screens";
import { FaqPreviews } from "@containers/faq-previews";
import { QuestionsTableSection } from "@containers/questions-table";
import { MainHeaderContainer } from "@containers/header";
import { Box } from "@components/box";

// Images
import trsipilotWidget from "@images/trustpilot-widget-img.png";
import postImg from "@images/fullscreen-background.jpg";

// Static data
import { FAQCREDITBUILDER } from "@containers/faq-previews/faq-previews-data";
import { STEPSCREDITBUILDER } from "@containers/steps/steps-data";
import { QUESTIONSCREDITBUILDER } from "@containers/questions-table/questions-data";
import { FaqPreviewAnswer, QuestionsTableRow, StepsItem, Video } from "@brainfinance/icash-component-library";

const CreditBuilderLoans = (props: any) => (
  <PageLayout>
    <SEO title="Credit builder loans" />
    <MainHeaderContainer
      description="A Credit builder loan is a cross between a savings program and a loan; you save money and build credit at the same time. All your payments report to the credit bureaus, which will improve your
      credit score."
      buttonText="Start building my credit"
      src={getImage(props.data.file)?.images.fallback?.src || ""}
      srcSet={getImage(props.data.file)?.images.fallback?.srcSet || ""}
    >
      <GrayPhrase>Credit builder <br/>loans</GrayPhrase> to grow <br/>your credit score
    </MainHeaderContainer>
    <Box className="h-[4rem]" />
    <StepsSection
      title="How credit builder loans works"
      gray="credit builder loans"
    >
      {STEPSCREDITBUILDER.map((item, ndx) => (
        <StepsItem key={ndx} {...item} />
      ))}
    </StepsSection>
    <PhoneScreens />
    <StatisticsList />
    <QuestionsTableSection
      title="Most common questions"
      gray="questions"
    >
      {QUESTIONSCREDITBUILDER.map((question, ndx) => (
        <QuestionsTableRow key={ndx} {...question} />
      ))}
    </QuestionsTableSection>
    <Video src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" poster={postImg} />
    <CreditGradeSection />
    <KeyValuesSection />
    <Box display="flex" className="justify-center py-[8rem] md:py-[5rem]">
      <img
        src={trsipilotWidget}
        alt="Trustpilot Widget"
        className="w-full max-w-[1408px]"
      />
    </Box>
    <FaqPreviews
      title="Frequently asked questions"
      gray="questions"
    >
      {FAQCREDITBUILDER.map((item, ndx) => (
        <FaqPreviewAnswer key={ndx} question={item.question}>
          {item.answer}
        </FaqPreviewAnswer>
      ))}
    </FaqPreviews>
    <CustomerService />
    <Testimonials title="Find out what our customers think about our credit builder loans!" gray="our customers" />
  </PageLayout>
);

export default CreditBuilderLoans;

export const pageQuery = graphql`
  query {
    file(relativePath: {eq: "credit-monitoring-bg.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1160)
      }
    }
  }
`