import * as React from "react";
import { Box } from "@components/box";
import { PhoneScreenTextBox } from "@components/phone-screen";

import { PHONE_SCREENS } from "./phone-screen-data";

export const PhoneScreens = () => {
  const [title, setTitle] = React.useState<string>("Set it and forget it!");

  const handleVisibilityChange = (intersectionRatio: number, title: string) => {
    if (intersectionRatio > 0.5) setTitle(title);
  };

  return (
    <Box display="flex" className="justify-center">
      <Box position="relative">
        <Box position="sticky" className="float-left top-4 md:top-[20vh] sm:hidden">
          <img
            src={PHONE_SCREENS[title].img}
            alt={title}
            className="w-[573px] lg:w-[473px] md:w-[374px]"
          />
        </Box>
        <Box className="float-left ml-[0px] w-[660px] lg:w-[520px] md:w-[362px] md:pl-[2rem] sm:pl-0 sm:px-[22px] sm:mx-0 sm:w-[100vw]">
          {Object.keys(PHONE_SCREENS).map((key) => (
            <Box key={key} display="flex" className="flex-col items-center sm:px-[22px]">
              <PhoneScreenTextBox
                title={key}
                onVisibilityChange={(intersectionRatio) => {
                  handleVisibilityChange(intersectionRatio, key);
                }}
                {...PHONE_SCREENS[key]}
              />
              <img
                src={PHONE_SCREENS[key].img}
                alt="key"
                className="hidden sm:block max-w-none w-[115vw] sm:max-w-[550px]"
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
